import React from 'react';
import { connect } from 'react-redux';
import { onlyUpdateForKeys } from 'react-recompose';
import PropTypes from 'prop-types';
import { Router, Route, browserHistory } from 'react-router';

import map from 'lodash/map';

import { FeatureFlags } from 'common/feature_flags';
import { getCurrentUserUid, getRouteConfig } from '../selectors';
import AccountEditor from './AccountEditor';
import AccountSettingsPane from './AccountSettingsPane';
import DeveloperSettingsPane from './DeveloperSettingsPane';
import EmailNotifications from './EmailNotifications';
import ProfilePane from './ProfilePane/ProfilePane';

import ConfigurationPropType from '../propTypes/ConfigurationPropType';

const AccountSettings = () => (
  <AccountEditor>
    <AccountSettingsPane>
      {!FeatureFlags.valueOrDefault('hide_legacy_email_notifications_settings', false) && (
        <EmailNotifications />
      )}
    </AccountSettingsPane>
  </AccountEditor>
);

AccountSettings.propTypes = {
  config: ConfigurationPropType
};

const DeveloperSettings = ({ route: { currentUserUid } }) => (
  <AccountEditor>
    <DeveloperSettingsPane userUid={currentUserUid} />
  </AccountEditor>
);

DeveloperSettings.propTypes = {
  config: ConfigurationPropType
};

const Profile = () => (
  <AccountEditor>
    <ProfilePane />
  </AccountEditor>
);

Profile.propTypes = {
  config: ConfigurationPropType
};

const components = {
  accountSettings: AccountSettings,
  developerSettings: DeveloperSettings,
  profile: Profile
};

//Avoid additional rerenders when we open and close forms (update the store)
const enhance = onlyUpdateForKeys(['routeConfig.basePath', 'routeConfig.routes']);

const EditAccount = enhance(({ currentUserUid, routeConfig: { basePath, routes } }) => {
  return (
    <Router history={browserHistory}>
      <Route path={basePath}>
        {routes &&
          map(routes, (value, key) => {
            return (
              <Route
                key={key}
                path={value.path}
                currentUserUid={currentUserUid}
                component={components[key]}
              />
            );
          })}
      </Route>
    </Router>
  );
});
EditAccount.propTypes = {
  locale: PropTypes.string
};

const mapStateToProps = (state) => ({
  routeConfig: getRouteConfig(state),
  currentUserUid: getCurrentUserUid(state)
});

export default connect(mapStateToProps)(EditAccount);
